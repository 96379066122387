


import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';
import AuthContent from '@/_components/auth-content/auth-content.vue';

@Component({
  name: 'auth-page',
  components: {
    AuthContent
  },
})
export default class AuthPage extends Vue {
  public created(): void {
    this.hideAuthPopup();
  }

  /* Hiding it so there are not two auths simultaneosly
   */
  public async hideAuthPopup(): Promise<void> {
    await this.$store.dispatch('authStore/setAuthPopupVisible', {
      isAuthPopupVisible: false,
      targetRoute: null
    });
  }
}
